import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainPanel from "@components-inbox/MainPanel/MainPanel.component";
import ConversationPanel from "@components-inbox/ConversationPanel/ConversationPanel";
import SummaryPanel from "@components-inbox/SummaryPanel/Summary.component";
import { loadSockets } from "@store-inbox/action";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { SideIcon } from "../styled";
import { Tooltip } from "antd";
import { payloadSelector } from "@store-inbox/MainPanel/selector";
import { useLocalStorage } from "@src/hooks/useLocalStorage";
import AppMenu from "../../../Menus/Menu";
import { Layout as Layouts } from "antd";
import QuickReply from "../QuickReplyPanel/QuickReply";
import { derivedConnectionsSelector } from "../MainPanel/utils";
import ReactHtmlParser from "react-html-parser";
import { currentActiveConversationSelector } from "@store-inbox/ConversationPanel/selector";
import { FaExclamation } from "react-icons/fa6";
import { getAllQuickReplies } from "@store-inbox/ConversationPanel/action";
import {
  getAgents,
  getConnections,
  getTemplates,
  getMsgsCount,
  getPopularTags,
  getFieldsAction,
  getEmailGroupsAction,
  getExotelAccountsAction,
} from "@store-inbox/MainPanel/action";

const Layout = () => {
  const dispatch = useDispatch();
  const customerId = useSelector(currentActiveConversationSelector);
  const [openPanel, setOpenPanel] = useState(false);
  const [activeKey, setActiveKey] = useLocalStorage(
    "ticket_status",
    "assigned"
  );
  const [defaultKey, setDefaultKey] = useState(null);
  const [replyPanel, setReplyPanel] = useState(false);
  const [input, setInput] = useState("");
  const [newChat, setNewChat] = useState(false);
  const [phone, setPhone] = useState("");
  const connections = useSelector(derivedConnectionsSelector);
  const [notification, setNotification] = useState(null);
  const [background, setBackground] = useState("#31B7F6");
  const [bottomNotification, setBottomNotification] = useState(null);
  const [template, setTemplate] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [accessInbox, setAccessInbox] = useState(true);

  const { Sider } = Layouts;

  const userDetails = localStorage.getItem("storeState");

  useEffect(() => {
    dispatch(getConnections());
    dispatch(getAgents());
    dispatch(getAllQuickReplies());
    dispatch(getTemplates());
    dispatch(getMsgsCount({ refresh: false }));
    dispatch(getPopularTags());
    dispatch(getFieldsAction());
    dispatch(getEmailGroupsAction());
    dispatch(getExotelAccountsAction());

    setTimeout(() => {
      dispatch(loadSockets());
    }, 1000);
  }, [dispatch]);

  const handleOpen = () => {
    setOpenPanel(!openPanel);
  };

  useEffect(() => {
    let regex =
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
    if (
      connections &&
      connections.dashboard_notification &&
      connections.dashboard_notification.message !== null
    ) {
      setNotification(
        connections.dashboard_notification.message.replace(
          regex,
          function (url) {
            return (
              '<a href="' +
              url +
              '" style="text-decoration: underline"}}>' +
              url +
              "</a>"
            );
          }
        )
      );
      if (connections.dashboard_notification.level == "info") {
        setBackground("#31B7F6");
      } else if (connections.dashboard_notification.level == "warning") {
        setBackground("#FFAA33");
      } else if (connections.dashboard_notification.level == "danger") {
        setBackground("#EB463D");
      }
    } else {
      setNotification(null);
    }

    if (
      connections &&
      !connections.logisy_enabled &&
      connections.disable_reason &&
      connections.disable_reason !== null
    ) {
      setBottomNotification(
        connections.disable_reason.replace(regex, function (url) {
          return (
            '<a href="' +
            url +
            '" style="text-decoration: underline"}}>' +
            url +
            "</a>"
          );
        })
      );
    } else {
      setBottomNotification(null);
    }
  }, [connections]);

  useEffect(() => {
    if (customerId && customerId !== null && customerId !== "") {
      setOpenPanel(true);
    } else {
      setOpenPanel(false);
    }
  }, [customerId]);

  useEffect(() => {
    if (userDetails && userDetails !== null) {
      let parsedDetails = JSON.parse(userDetails);
      let userPermissions = parsedDetails.auth.user.user_permissions;
      let index = userPermissions.indexOf("access_connect_chat_page");
      if (index !== -1) {
        setAccessInbox(true);
      } else {
        setAccessInbox(false);
      }
    }
  }, [userDetails]);

  return (
    <Fragment>
      {notification && notification !== null && (
        <div
          style={{
            background: background,
            width: "100%",
            color: "#000",
            textAlign: "center",
            padding: "0.5rem",
          }}
        >
          {ReactHtmlParser(notification)}{" "}
          {connections.dashboard_notification.redirect_link !== null &&
            connections.dashboard_notification.redirect_link !== "" && (
              <a
                href={connections.dashboard_notification.redirect_link}
                style={{ color: "inherit", textDecoration: "underline" }}
                target="_blank"
              >
                {" "}
                Click here
              </a>
            )}{" "}
        </div>
      )}
      <div style={{ display: "flex" }}>
        <Sider className="sider" collapsed={true} theme="light">
          <div className="top-aside">
            <AppMenu />
          </div>
        </Sider>
        {!accessInbox && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              background: "rgba(24, 28, 50, 0.5)",
              padding: "20px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh",
              zIndex: "9999",
              marginLeft: "65px",
            }}
          >
            <div
              style={{
                background: "#fff",
                padding: "30px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  background: "#F5D2E0",
                  padding: "10px 14px",
                  borderRadius: "50%",
                }}
              >
                <FaExclamation color="#f1416c" size={30} />
              </div>
              <div style={{ marginTop: "10px" }}>
                <h1 style={{ textAlign: "center" }}>Feature not subscribed!</h1>
                <p>
                  You have not subscribed for this feature. For more information
                  please contact <strong> support@logisy.tech</strong>
                </p>
              </div>
            </div>
          </div>
        )}
        <MainPanel
          template={template}
          setTemplate={setTemplate}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          newChat={newChat}
          setNewChat={setNewChat}
          phone={phone}
          setPhone={setPhone}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
        />
        <ConversationPanel
          template={template}
          setTemplate={setTemplate}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          newChat={newChat}
          setNewChat={setNewChat}
          phone={phone}
          setPhone={setPhone}
          input={input}
          setInput={setInput}
          replyPanel={replyPanel}
          setReplyPanel={setReplyPanel}
          setDefaultKey={setDefaultKey}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          openpanel={openPanel}
          setOpenPanel={setOpenPanel}
        />
        <QuickReply
          input={input}
          setInput={setInput}
          replyPanel={replyPanel}
          setReplyPanel={setReplyPanel}
          openpanel={openPanel}
        />
        <div style={{ position: "relative" }}>
          <Tooltip title="More details" placement="right">
            <SideIcon
              onClick={handleOpen}
              style={openPanel ? { height: "27px" } : { height: "100px" }}
            >
              {openPanel ? <CaretRightOutlined /> : <CaretLeftOutlined />}
            </SideIcon>
          </Tooltip>
        </div>
        {openPanel && <SummaryPanel defaultKey={defaultKey} />}
      </div>
      {bottomNotification && bottomNotification !== null && (
        <div
          style={{
            background: "#EB463D",
            zIndex: "99",
            width: "100%",
            color: "#fff",
            textAlign: "center",
            padding: "0.5rem",
            bottom: "0px",
            position: "fixed",
          }}
        >
          {bottomNotification}
        </div>
      )}
    </Fragment>
  );
};

export default React.memo(Layout);
