import React, { useEffect } from "react";
import { Layout } from "antd";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import Inbox from "./components/Inbox";
import { setProfile } from "@store-inbox/action";
import Validate from "./Validate";

const { Content } = Layout;

function AppRoute(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    const saved = localStorage.getItem("storeState");
    if (!saved) {
      if (process.env.NODE_ENV !== "development") {
        window.location.replace(`${process.env.REACT_APP_ORIGIN}/users/login/`);
      }
    } else {
      dispatch(setProfile(JSON.parse(saved)));
    }
  }, []);

  return (
    <Layout className="layout">
      <Content>
        <Switch>
          <Route exact path="/">
            <Redirect from="/" to="/inbox/" />
          </Route>
          <Route path="/validate">
            <Validate />
          </Route>
          <Route path="/inbox/:id">
            <Inbox />
          </Route>
          <Route path="/inbox">
            <Inbox />
          </Route>
        </Switch>
      </Content>
    </Layout>
  );
}

export default AppRoute;
