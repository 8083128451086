import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Modal, Radio, Skeleton, Tooltip, Space, Divider, Button, Collapse } from 'antd';
import { format, parseISO } from 'date-fns';
import Icon, { CaretDownOutlined, CaretUpOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { StyledLog, StyledMeta, StyledParserMessage, StyleDelete, StyledAgentIntial, StyledParent, StyledTicketHistory, StyledGoTo } from './styled';
import Attachment from './Attachment';
import { StyledMessageContainer, StyledEmail } from '../styled';
import Bot from './Bot';
import { loadingSelector, commentLoader, newCommentID } from '@store-inbox/ConversationPanel/selector';
import { ReactComponent as SentIcon } from '@assets/icons/message/sent.svg';
import { ReactComponent as SeenIcon } from '@assets/icons/message/seen.svg';
import { ReactComponent as DeliveredIcon } from '@assets/icons/message/newdelivered.svg';
import { ReactComponent as DeletedIcon } from '@assets/icons/message/deleted.svg';
import ReactHtmlParser from 'react-html-parser';
import { getInitials } from '@utils/functions';
import { derivedAllTickets, derivedFullNameDataSelector } from '../utils';
import { ReactComponent as DeleteIcon } from '@assets/icons/message/delete.svg';
import { ReactComponent as ListIcon } from '@assets/icons/message/list.svg';
import { deleteComment } from '@store-inbox/ConversationPanel/action';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { ReactComponent as ReplyIcon } from '@assets/icons/message/reply.svg';
import { ReactComponent as ReplyallIcon } from '@assets/icons/message/replyall.svg';
import { derivedConnectionsSelector } from '../../MainPanel/utils';
import { ReactComponent as DownArrowIcon } from '@assets/icons/main/downarrow.svg';
import { ReactComponent as DownloadIcon } from '@assets/icons/message/download.svg';
import { ReactComponent as DocxIcon } from '@assets/icons/message/docx.svg';
import { ReactComponent as PdfIcon } from '@assets/icons/message/pdficon.svg';
import { ReactComponent as GenericIcon } from '@assets/icons/message/genericicon.svg';
import { DownOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { calcTimeDelta } from 'react-countdown';
import { CloseCircleFilled } from '@ant-design/icons';
import { LuForward } from "react-icons/lu";
import { jsx } from 'slate-hyperscript';

const deserialize = (html) => {
    const parser = new DOMParser();
    const document = parser.parseFromString(html, 'text/html');
    const { body } = document;

    const deserializeNode = (element) => {

      if (element.nodeType === 3) {
        return { text: element.textContent || '' }; // Handle simple text nodes
      }
  
      if (element.nodeName === 'BR') {
        return { text: '' }; // Handle line breaks
      }
  
      const children = Array.from(element.childNodes).map(deserializeNode);
  
      if (children.length === 0) {
        children.push({ text: '' });
      }
  
      // For inline elements, concatenate text nodes
      const isInline = ['SPAN', 'B', 'STRONG', 'I', 'EM', 'A'].includes(element.nodeName);
      if (isInline) {
        // Concatenate text content if it has multiple children
        const singleText = children.map(child => child.text || '').join('');
        
        switch (element.nodeName) {
          case 'SPAN':
            return { text: singleText }; // Handle SPAN as plain text
          case 'A':
            return jsx('element', { type: 'link', url: element.getAttribute('href') }, [{ text: singleText }]);
          case 'B':
          case 'STRONG':
            return { text: singleText, bold: true }; // Bold text
          case 'I':
          case 'EM':
            return { text: singleText, italic: true }; // Italic text
          default:
            return { text: singleText }; // Default handling for inline
        }
      }
  
      // For block elements, allow children
      switch (element.nodeName) {
        case 'BODY':
          return jsx('fragment', {}, children);
        case 'P':
          return jsx('element', { type: 'paragraph' }, children);
        case 'UL':
          return jsx('element', { type: 'unordered-list' }, children);
        case 'OL':
          return jsx('element', { type: 'ordered-list' }, children);
        case 'LI':
          return jsx('element', { type: 'list-item' }, children);
        case 'DIV':
          return  jsx('element', { type: 'paragraph'}, children );
        case 'BLOCKQUOTE':
          return  jsx('element', { type: 'blockquote'}, children );
        default:
          return jsx('element', { type: 'paragraph' }, children);;
      }
    };
  
    return { type: 'fragment', children: Array.from(body.childNodes).map(deserializeNode) };
};

export const Time = ({ created_at }) => {
    if (Date.parse(created_at)) {
        return (
            <div className="time">{format(Date.parse(created_at), 'dd MMM yyyy, hh:mm a')}</div>
        )
    }
    return <div className="time">just now</div>;
}

export function waContentToHtml(waContent) {
    // Formatting bold
    waContent = waContent.replace(/\*(.*?)\*/g, `<b>$1</b>`);
    // Formatting italic
    waContent = waContent.replace(/\_(.*?)\_/g, '<i>$1</i>');
    // Formatting strike
    waContent = waContent.replace(/\~(.*?)\~/g, '<strike>$1</strike>');
    // Formatting mono
    waContent = waContent.replace(/\`(.*?)\`/g, '<span class="text-monospace">$1</span>');
    // Formatting new line
    waContent = waContent.replace(/\n/g, '<br />');
    return waContent;
};

toast.configure();

const MessageStringParser = ({message})=>{
    const newElements = [];

    const parts = message.split(/BUTTON \d\(([^)]+)\)/gi);
    const msgText =  parts[0];
    const allElements = [];
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selected,setSelected] = useState(null);

    const showModal = () => {
      setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    for (let i = 1; i < parts.length; i += 2) {
        if (parts[i]) {
            newElements.push(<Button className="message-button" key={i}>{parts[i]}</Button>);
            allElements.push(parts[i]);
        }
    }

    const ModalTitle = ()=>{
        return(
            <p style={{color: '#333',fontWeight: '600',margin: '0',fontSize: '20px'}}>Main Menu</p>
        )
    }
    return (
        <div>
            <span>{ReactHtmlParser(waContentToHtml(msgText))}</span>
            {/* <div className="buttons-container">{newElements}</div> */}
            {allElements.length > 0 && 
                <Fragment>
                    <p style={{color: '#1d8dcc',display:'flex',cursor: 'pointer',justifyContent: 'center',alignItems: 'center'}} onClick={showModal}> <ListIcon stroke="#1d8dcc" /> Main Menu</p>
                    <Modal footer={false} centered title={<ModalTitle />} visible={isModalVisible} onCancel={handleCancel}>
                        <Radio.Group disabled row aria-label="gender" name="main-menu-buttons-group">
                            <Space direction="vertical">
                                {allElements.map((buttonName)=>{
                                    return(
                                            <Radio key={buttonName} className='menu-buttons' value={buttonName} style={{fontSize: '16px',color: '#474747'}} control={<Radio />} label={buttonName}>{buttonName}</Radio>
                                        )
                                    })
                                }
                            </Space>
                        </Radio.Group>
                    </Modal>
                </Fragment>
            }

        </div>);
}

const Message = (props) => {
    const { messages, content_type, inputElement, setCommentReply, setCommentName, setInput, setCommentID, setPostID, conversation_source, setHideReply, setCommentIndex, setFromID, fromEmail, account_id, compose, setCompose, toEmail, ccEmail, setEmailMsgID, msgID, setCcID, fromID, setCC, subject, setToID, status, setValue, setDisableSubject} = props;
    const fullName = useSelector(derivedFullNameDataSelector);
    const dispatch = useDispatch();
    const commentLoading = useSelector(commentLoader);
    const latestCommentID = useSelector(newCommentID);
    const connections = useSelector(derivedConnectionsSelector);

    const accountID = Object.keys(connections).filter(a=>a === account_id);

    const [openDown, setOpenDown] = useState(false);

    useEffect(()=>{
        if(account_id !== undefined){
            setFromID(connections[accountID[0]]);
        }
        if(msgID !==undefined){
            setEmailMsgID(msgID);
        }
    },[account_id,msgID, connections])

    useEffect(()=>{
        if(account_id !== undefined){
            setToID([fromEmail]);
        }
    },[])

    useEffect(()=>{
        if(!compose){
            setToID([fromEmail]);
        }
    },[compose])
    const [replyHighlight,setReplyHighlight] = useState("");
    const allTickets = useSelector(derivedAllTickets);

    const handleScrollTo = (id) =>{
        const index = document.getElementById(id);
        index !== null && index.scrollIntoView({behavior: 'smooth',block: 'nearest', inline: 'start'});
        index.style.transition = "0.5s";
        index.style.background = "#E8EBED";
        setReplyHighlight(index);
    }

    useEffect(()=>{
        setTimeout(()=>{
            if(replyHighlight){
              replyHighlight.style.background = "#E8F3FA";
            }
        }, 500);
        setReplyHighlight(null);
    },[replyHighlight])

    if (content_type === 'message') {
        const { message: { message_type, actor, message, created_at, attachment, delivered, read, deleted, message_sent_by, parent_message_id, parent_actor, parent_message, message_id, parent_msg_type, parent_attachment, api_response, success, error, send_status, error_message }} = props;
        let agentName = null;
        if(message_sent_by && message_sent_by !==null){
            agentName = (message_sent_by.first_name + ' ' + message_sent_by.last_name);
        }
        if (message_type == "session_outbound_bot") {
            return <Bot data={props.message}></Bot>
        } else {
            if (attachment && attachment !== null) {
                return (
                    <div className="message__row" id={message_id}>
                        {(actor === "merchant" && message_sent_by !==null && message_sent_by !==undefined) ?
                            <StyledAgentIntial>
                                <Tooltip
                                placement='bottom'
                                title={agentName}
                                >
                                    <span style={{color: '#474747',display: 'flex',justifyContent: 'center',alignItems: 'center',marginTop: '8px',fontSize: '16px'}}>{getInitials(agentName)}</span> 
                                </Tooltip>
                            </StyledAgentIntial>
                            : actor === "merchant" && !success && error!==null && send_status === "not_sent" ?
                            <StyledAgentIntial style={{background: 'transparent', right: '-8px'}}>
                               <Tooltip
                               placement='bottom'
                               title="Failed to send"
                               >
                                   <ExclamationCircleFilled style={{color: 'red', fontSize: '24px'}} />
                               </Tooltip>
                            </StyledAgentIntial>
                            : actor === "merchant" && send_status === 'sending' &&
                            <StyledAgentIntial style={{background: 'transparent'}}>
                               <p style={{fontSize: '12px', color: '#5b5b5b'}}>Sending</p>
                            </StyledAgentIntial>
                        }
                        {attachment.msg_type === "story_replies" ? <div style={{borderLeft: '3px solid #5b5b5b',width: '14px',height: '60px',position: 'absolute',left: '-10px',marginRight: '20px',top:'40px'}}></div> : ""}
                        <span className={`message message-file message--${actor}`} style={attachment.msg_type === "story_replies" ? {marginTop: '40px'} : {}}>
                            {attachment.msg_type ? <span className="mention" style={{color: "#5B5B5B", fontSize: "16px",position: 'relative',top:'-30px'}}>{attachment.msg_type_name}</span> : null}
                            {deleted ? <StyleDelete><span className='deleteicon'><DeletedIcon className='delete'/></span><p className='deletetext'>This message was deleted by the sender</p></StyleDelete> : 
                            <Fragment>
                                <Attachment attachment={attachment} actor={actor} />
                                {message ? (
                                    <div>
                                        {error_message && error_message !== null && 
                                            <p style={{background: '#FBEDEF', padding: '10px', marginBottom: '10px', fontSize: '14px' }}>
                                                <CloseCircleFilled style={{color: '#DF3E38', marginRight: '6px'}} />
                                                {error_message}
                                            </p>
                                        }
                                        <div className="actual-message" style={{padding: '0px 10px 20px',wordWrap: 'break-word',whiteSpace:'pre-wrap',maxWidth: '350px'}}>
                                            <span>{message}</span>
                                        </div>
                                    </div>
                                )
                                : null}
                                <StyledMeta actor={actor}>
                                    <Time created_at={created_at} />
                                    {(actor === 'merchant' && !(error_message && error_message !== null)) ? (
                                        <Fragment>
                                            {(conversation_source === "instagram" || conversation_source === "messenger" || delivered || (!success && error!==null && send_status === "not_sent") || send_status === 'sending') ? null: <div style={{display: 'flex',alignItems: 'center'}}><Icon component={SentIcon} className="icon-read" /><span style={{color: '#6F6F6F'}}> Sent</span></div>}
                                            {((conversation_source === "instagram" || conversation_source === "messenger" || delivered) && !read) ? <div style={{display: 'flex',alignItems: 'center'}}><Icon component={DeliveredIcon} className="icon-read" /><span style={{color: '#6F6F6F',fontSize: '12px'}}> Delivered</span></div>: null}
                                            {read ? <div style={{display: 'flex',alignItems: 'center'}}><Icon component={SeenIcon} className="icon-read" /><span style={{color:'#16AC37',padding:'0 5px',fontSize: '12px'}}> Seen</span></div> : null}
                                        </Fragment>
                                    )
                                    : null}
                                </StyledMeta>
                            </Fragment>
                            }
                        </span>
                    </div>
                )
            } else {
                return (
                    <div className="message__row" id={message_id}>
                        {(actor === "merchant" && message_sent_by && message_sent_by !==null) ?
                            <StyledAgentIntial>
                                <Tooltip
                                placement='bottom'
                                title={agentName}
                                >
                                    <span style={{color: '#474747',display: 'flex',justifyContent: 'center',alignItems: 'center',marginTop: '8px',fontSize: '16px'}}>{getInitials(agentName)}</span> 
                                </Tooltip>
                            </StyledAgentIntial>
                            : actor === "merchant" && !success && error!==null && send_status === "not_sent" ?
                             <StyledAgentIntial style={{background: 'transparent', right: '-8px'}}>
                                <Tooltip
                                placement='bottom'
                                title="Failed to send"
                                >
                                    <ExclamationCircleFilled style={{color: 'red', fontSize: '24px'}} />
                                </Tooltip>
                            </StyledAgentIntial>
                            : actor === "merchant" && send_status === 'sending' &&
                            <StyledAgentIntial style={{background: 'transparent'}}>
                                <p style={{fontSize: '12px', color: '#5b5b5b'}}>Sending</p>
                            </StyledAgentIntial>
                        }
                        <span className={`message message--${actor}`}>
                            {error_message && error_message !== null && 
                                <p style={{background: '#FBEDEF', padding: '10px', marginBottom: '10px' }}>
                                    <CloseCircleFilled style={{color: '#DF3E38', marginRight: '6px', marginTop: '4px'}} />
                                    {error_message}
                                </p>
                            }
                        {deleted ? <StyleDelete><span className='deleteicon'><DeletedIcon className='delete'/></span><p className='deletetext'>This message was deleted by the sender</p></StyleDelete> : 
                        <Fragment>
                            {message !== "" &&
                            <div className="actual-message">
                                {parent_message_id && parent_message_id!==null && <StyledParent onClick={()=>handleScrollTo(parent_message_id)}>
                                    <div>
                                        <p style={{margin: '0',color: '#1d8dcc',fontWeight: '600',textTransform: 'capitalize'}}>{parent_actor === "merchant" ? "You" : fullName}</p>
                                        <p style={{margin: '0',color: '#1f1f1f'}}>{parent_message} {parent_attachment && <span style={{textTransform: 'capitalize'}}>{parent_attachment.content_type}</span>}</p>
                                    </div>
                                    <img alt='' style={{objectFit: 'contain',height: '40px',marginLeft: 'auto',display: 'block'}} src={parent_attachment && parent_attachment.url} />
                                </StyledParent>
                                }
                                <span>
                                    <StyledParserMessage><MessageStringParser message={message} /></StyledParserMessage>
                                    <StyledMeta actor={actor}>
                                        <Time created_at={created_at} />
                                        {(actor === 'merchant' && !(error_message && error_message !== null)) ? (
                                            <Fragment>
                                                {((conversation_source === "instagram" || conversation_source === "messenger" || delivered || (!success && error!==null && send_status === "not_sent") || send_status === 'sending')) ? null: <div style={{display: 'flex',alignItems: 'center'}}><Icon component={SentIcon} className="icon-read" /><span style={{color: '#6F6F6F'}}> Sent</span></div>}
                                                {((conversation_source === "instagram" || conversation_source === "messenger" || delivered) && !read) ? <div style={{display: 'flex',alignItems: 'center'}}><Icon component={DeliveredIcon} className="icon-read" /><span style={{color: '#6F6F6F',fontSize: '12px'}}> Delivered</span></div>: null}
                                                {read ? <div style={{display: 'flex',alignItems: 'center'}}><Icon component={SeenIcon} className="icon-read" /><span style={{color:'#16AC37',padding:'0 5px',fontSize: '12px'}}> Seen</span></div> : null}
                                            </Fragment>
                                        )
                                        : null}
                                    </StyledMeta>
                                </span>
                            </div>
                            }
                        </Fragment>
                        }
                        </span>
                    </div>
                )
            }
        }
    } else if (content_type === 'log') {
        const { message: { comment, event, created_at, id } } = props;
        return (
            <div className="message__row" id={id}>
                <StyledLog>
                    {comment} - <span style={{color: '#474747'}}>{format(parseISO(created_at), 'LLL d yyyy, HH:mm aaa')}</span>
                </StyledLog>
            </div>
        )
    } else if (content_type === "comment"){
        const { message : { merchant_name, post_url, permalink, comment_data, post_caption, post_type, created_at, post_ad_type }} = props;
        
        const openReplies = (newReplyID, setNewReplyID, e, setOpenReply, openReply)=>{
            if(newReplyID === e.target.getAttribute("name")){
                return setNewReplyID(null)
            }
            setNewReplyID(e.target.getAttribute("name"));
            setOpenReply(!openReply);
        }

        const handleReply = (name, commentID, postid, hideValue, i)=>{
            inputElement.current.focus();
            setCommentReply(true);
            setCommentName(name);
            setInput(`@${name} `);
            setCommentID(commentID);
            setPostID(postid);
            setHideReply(hideValue);
            setCommentIndex(i);
        }

        const handleDelete = (commentID, postID,i)=>{
            dispatch(deleteComment({
                conversation_source: conversation_source,
                comment_id : commentID,
                post_id: postID,
                index: i,
            }))
        }

        const CommentsStructure = ({children,comment,i, name, comment_id})=>{
            const [openReply,setOpenReply] = useState(false);
            const [newReplyID,setNewReplyID] = useState(comment_id);

            return(
                <div style={{position: 'relative'}}>
                    {(!(comment[comment_id].deleted)) &&
                        <Fragment>
                            <div style={{display: 'flex',alignItems: 'center', width: '100%'}}>
                                <Avatar className="initial-avatar" style={{background: 'var(--color-icon-background)',fontSize: '12px',width: '30px',height: '30px'}}>{getInitials(name)}</Avatar>     
                                <p style={{margin: '0px 0px',color: '#474747',fontWeight: '600',fontSize: '16px',marginLeft: '15px'}}>{name}</p>
                                {comment[comment_id].comment_dt_time && comment[comment_id].comment_dt_time !== null && <p style={{marginLeft: 'auto', color: "#474747", fontSize: '12px'}}>{format(Date.parse(comment[comment_id].comment_dt_time), 'dd MMM ,hh:mm a')}</p>}
                                {/* {!(commentLoading && latestCommentID==comment_id) && <p style={{margin: '0px 0px 0px auto',color: '#1D8DCC', cursor: 'pointer'}} onClick={()=>handleReply(comment[comment_id].username,comment_id,comment.post_id,true,i)}>
                                    Reply and Hide
                                </p>} */}
                            </div>
                            <p style={{margin: '10px 0px',textAlign: 'left',fontSize: '16px',color: '#1f1f1f',fontWeight: '200'}}>{comment[comment_id].comment}</p>
                            {!(commentLoading && latestCommentID==comment_id) && 
                                <div style={{display: 'flex',alignItems: 'center',justifyContent: 'space-between',width: '100%'}}>
                                    <div style={{display: 'flex',alignItems: 'center',width: '100%'}}>
                                        {!(comment[comment_id].error) && 
                                            <Fragment>
                                                <span style={{cursor: 'pointer'}} onClick={()=>handleReply(comment[comment_id].username,comment_id,comment.post_id,false,i)}>Reply</span>
                                                {children.length > 0 &&  <span onClick={(e)=>openReplies(newReplyID,setNewReplyID, e, setOpenReply, openReply)} name={comment_id} style={{cursor: 'pointer',marginLeft: '20px'}}>
                                                {(newReplyID == comment_id) ? 'Hide' : 'Show'} ({comment[comment_id].children.length}) {comment[comment_id].children.length > 1 ? 'replies' :'reply'}</span>
                                            }
                                            </Fragment>
                                    }
                                    </div>
                                    {!(comment[comment_id].error) &&  <DeleteIcon style={{margin: ' 0 10px 0 0px', cursor: 'pointer'}} onClick={()=>handleDelete(comment_id,comment.post_id,i)} /> }
                                </div>
                            }
                            {(newReplyID == comment_id) &&
                                <div style={{marginTop: '10px',borderLeft: '2px solid #d1d1d1',paddingLeft: '10px'}}>
                                    {children.map((child)=>{
                                        return(
                                            <div style={{textAlign: 'left',margin: '30px 0px'}} key={comment[child].comment}>
                                                <CommentsStructure comment={comment} i={i} children={comment[child].children} name={comment[child].username} comment_id={child} />
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            <div style={{marginLeft: '50px',position:'absolute',left: '102.5%',width: '100%',top: '25px'}}>
                                {!(comment[comment_id].error) ? 
                                    <p style={{margin: '0',color: '#6F6F6F',fontWeight: '400'}}> {(commentLoading && latestCommentID==comment_id) ? "Sending" : <CommentTime created_at={comment[comment_id].created_at} /> } </p>
                                    : <p style={{margin: '0',color: 'red'}}>{(comment[comment_id].error)}</p>
                                }
                            </div>
                        </Fragment>
                    }
                </div>
            )
        }

        const CommentTime = (({created_at})=>{
            if (Date.parse(created_at)) {
                return (
                    <span className="time">{moment(Date.parse(created_at)).fromNow()}</span>
                )
            }
            return <span></span>
            // return <span className="time">just now</span>
        })

        return (
            <div className='message__row' style={{display: 'flex',flexDirection: 'column',width: '60%'}}>
                <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center'}}>
                    <div style={{textAlign: 'left',background: 'white',width: '250px',height: '360px',borderRadius:'14px',border: '1px solid #1D8DCC80'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <p style={{padding:'10px',color: '#333333',fontSize: '18px',margin: '0'}}>{merchant_name}</p>
                            {post_ad_type && post_ad_type === "FEED" && <p style={{background: "var(--color-primary)", padding: "2px 10px",marginLeft: "2px",color: "#fff",borderRadius: "4px", fontSize: "12px"}}>AD</p>}
                        </div>
                        <Divider style={{margin: '0px 0px'}} />
                        {post_url && 
                            <a href={permalink} target="_blank" rel="noopener noreferrer">
                                {post_type.toLowerCase().includes("video") ? <ReactPlayer url={post_url} width="240px" height="250px"  /> : 
                                    <img src={post_url} alt="" style={{width: '248px',height: '250px'}} />
                                }
                            </a>
                        }
                        {post_caption && <p style={{display: '-webkit-box',margin: '10px',color: '#1f1f1f',width: '90%',WebkitLineClamp: '2',WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>{post_caption}</p>}
                    </div>
                    <div>
                        <p style={{margin: '0',color: '#5B5B5B',fontWeight: '400'}}>{fullName} commented on this post.<span style={{color: '#474747',fontWeight: '600'}} ><CommentTime created_at={created_at} /> </span></p>
                    </div>
                </div>
                {comment_data && comment_data.map((comment,i)=>{
                    return(
                        <div style={{display: 'flex'}} key={i}>
                        {(!(comment[comment.root_comment_id].deleted)) &&
                            <Fragment>
                                <div className={`message`} key={comment.root_comment_id} style={{background: 'white',float: 'left',padding: '1em',borderRadius: '1px 14px 14px 14px',border: '1px solid #1D8DCC80',marginTop: '10px', minWidth: '300px',maxWidth: '80%'}}>
                                    <CommentsStructure comment={comment} i={i} children={comment[comment.root_comment_id].children} name={fullName} comment_id={comment.root_comment_id} />
                                </div>
                                <div style={{marginLeft: '50px', marginTop: '50px'}}>
                                {!(comment[comment.root_comment_id].error) ? 
                                    <p style={{margin: '0',color: '#6F6F6F',fontWeight: '400'}}> {(commentLoading && latestCommentID==comment.root_comment_id) ? "Sending" : <CommentTime created_at={comment[comment.root_comment_id].created_at} /> } </p>
                                    : <p style={{margin: '0',color: 'red'}}>{(comment[comment.root_comment_id].error)}</p>
                                }
                                </div>
                            </Fragment>
                        }
                        </div>
                )})}
            </div>
        )
    } else if (content_type === "email"){
        const { message: { actor, message, created_at, attachment, from, to, cc, bcc, id, error_message }} = props;
        const openCC = ()=>{
            if(cc.length > 0 || bcc.length > 0){
                setOpenDown(!openDown);
            }
        }
        const { Panel } = Collapse;

        const handleReply =(actor)=>{
            setDisableSubject(true);
            if(status == "closed"){
                setCompose(false);
                toast.error("You cannot reply as Ticket is closed.",{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
            }else{
                setCompose(true);
                if(fromEmail!==undefined){
                    if(actor === "customer"){
                        setToID([fromEmail]);
                        setCcID([]);
                        setCC(false);
                    }else if(actor === "merchant"){
                        setToID([toEmail[0].email]);
                        setCcID([]);
                        setCC(false);
                    }
                }
            }
        }

        const handleReplyAll = ()=>{
            setDisableSubject(true);
            if(fromEmail!==undefined){
                const replytoEmails = toEmail.filter(a=>a.email!==fromID);
                const toEmails = replytoEmails.map(a=>a.email);
                const ccEmails = ccEmail.map(a=>a.email);
                if(actor === "customer"){
                    setToID([...new Set(toEmails),fromEmail]);
                    setCcID(ccEmails);
                    if(ccEmail.length > 0){
                        setCC(true);
                    }
                }else if(actor === "merchant"){
                    setToID([...new Set(toEmails)]);
                    setCcID(ccEmails);
                    if(ccEmail.length > 0){
                        setCC(true);
                    }
                }
            }
            if(status == "closed"){
                setCompose(false);
                toast.error("You cannot reply as Ticket is closed.",{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
            }else{
                setCompose(true);
            }
        }

        const handleForward = (selectedId) => {
            setCompose(true);
            setToID([]);
            setCcID([]);
            setCC(false);
            setDisableSubject(false);
        
            const selectedIndex = messages.findIndex((msg) => msg.id === selectedId);
        
            const precedingEmails = messages
            .slice(selectedIndex) // Get all messages before the selected one
            .filter((msg) => msg.content_type === "email")
            .map((msg) => {
                // Create the nodes for the metadata
                const metadataNodes = [
                    {
                        type: 'paragraph',
                        children: [{ text: '' }],
                    },
                    {
                        type: 'paragraph',
                        children: [{ text: '---------------------------------------------' }],
                    },
                    {
                        type: 'paragraph',
                        children: [{ text: `From: ${msg.from.email}` }],
                    },
                    {
                        type: 'paragraph',
                        children: [
                        { text: `Date: ${format(Date.parse(msg.created_at), 'dd MMM yyyy, hh:mm a')}` },
                        ],
                    },
                    {
                        type: 'paragraph',
                        children: [{ text: `Sub: ${subject}` }],
                    },
                    {
                        type: 'paragraph',
                        children: [{ text: `To: ${msg.to.map(a => a.email).join(', ')}` }],
                    },
                    ...(msg.cc.length > 0
                        ? [
                            {
                                type: 'paragraph',
                                children: [{ text: `cc: ${msg.cc.map(a => a.email).join(', ')}` }],
                            },
                        ]
                        : []),
                    {
                        type: 'paragraph',
                        children: [{ text: '---------------------------------------------' }],
                    },
                ];
        
                // Deserialize the message content into Slate nodes
                const messageNodes = deserialize(msg.message);
        
                // Combine the metadata and the actual message content
                return [...metadataNodes, messageNodes];
            });
        
            // Flatten the nested arrays into a single array
            const emailContent = precedingEmails.flat();
        
            // Set the Slate editor's value with the content
            setValue(emailContent);

            setTimeout(()=>{
                document.querySelector(".email-editor")?.focus();
                document.querySelector(".email-editor")?.setAttribute("autoFocus", true);
            }, 500)
        };
        
        return(
            <div className="message__row">
                <div className={`message message--${actor}`} style={{marginRight: '0px',background: '#fff',maxWidth: '1000px',width:'100%',overflowX: 'hidden'}}>
                    {error_message && error_message !== null && 
                        <p style={{background: '#FBEDEF', padding: '10px', marginBottom: '10px', fontSize: '14px' }}>
                            <CloseCircleFilled style={{color: '#DF3E38', marginRight: '6px'}} />
                            {error_message}
                        </p>
                    }
                    <div className="actual-message">
                        <div style={{display: 'flex',width: '100%',justifyContent: 'space-between'}}>
                            <p style={{margin:'0',color: '#5B5B5B'}}>From: {from && from!== null && from.email}</p>
                            <div className='email-time'>
                                <Time created_at={created_at} />
                            </div>
                        </div>
                        <p style={{margin:'0',color: '#5B5B5B'}}>To: {to && to!==null && to.map((ids)=>{
                            return(
                                <span>{ids.email} {to.length > 1 && <Fragment>,</Fragment>}</span>
                            )
                        })}
                        {(cc && bcc && cc!==null && bcc!==null && (cc.length > 0 || bcc.length > 0) && openDown) ? <CaretUpOutlined onClick={()=>openCC()} /> : (cc && bcc && cc!== null && bcc !== null && (cc.length > 0 || bcc.length > 0) && !openDown) ? <CaretDownOutlined onClick={()=>openCC()} /> : null }
                        </p>
                        {openDown && <Fragment>
                                        {cc.length > 0 && <p style={{margin:'0',color: '#5B5B5B'}}>cc: {cc.map((ids)=>{
                                            return(
                                                <span key={ids.email}>{ids.email} {cc.length > 1 && <Fragment>,</Fragment>}</span>
                                            )
                                        })}</p>}
                                        {bcc.length > 0 && <p style={{margin:'0',color: '#5B5B5B'}}>bcc: {bcc.map((ids)=>{
                                            return(
                                                <span key={ids.email}>{ids.email} {bcc.length > 1 && <Fragment>,</Fragment>}</span>
                                            )
                                        })}</p>}
                                    </Fragment>
                        }
                        {subject && <p style={{margin:'0',color: '#5B5B5B'}}>Sub: {subject}</p>}
                        <StyledEmail>{ReactHtmlParser(message)}</StyledEmail>
                        {attachment && attachment !== null && attachment.filter(a=>a.content_disposition == "attachment").length > 0 && <Fragment>
                            <Divider style={{margin: '0',marginBottom: '10px'}} />
                            <Collapse ghost="true" accordion="true" expandIconPosition="right" bordered={false} expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? -180 : 0} />}>
                                <Panel header={<span>Attachment{attachment.filter(a=>a.content_disposition == "attachment").length>1 ? "s" : ""} ({attachment.filter(a=>a.content_disposition == "attachment").length})</span>} key="1">
                                {attachment.filter(a=>a.content_disposition == "attachment").map((data)=>{
                                    return(
                                        <a href={data.url} target="_blank" download>
                                            <div key={data.id} style={{display:'flex',color: '#474747',width: '100%',alignItems: 'center',marginTop: '10px'}}>
                                                    {data.content_type.indexOf("image") !==-1 ? <img style={{width: '40px',height: '40px'}} src={data.url} alt="Preview" /> : data.content_type.indexOf("video") !== -1 ? <ReactPlayer url={data.url} width="40px" height="40px" /> : data.content_type.indexOf("pdf") ? <PdfIcon width="40px" height="40px" /> : data.content_type.indexOf("docx") ? <DocxIcon width="40px" height="40px" /> : <GenericIcon width="40px" height="40px" /> }
                                                    <p style={{margin: '0',marginLeft: '10px',fontWeight: '600',width: '60%'}}>{data.filename}</p>
                                                    <p style={{margin: '0 10px',width: '20%',fontSize: '12px'}}>{data.size < 1000000 ? Math.round(((data.size/1000)*10))/10 + ' KB' : Math.round(((data.size/1000000)*10))/10 + ' MB'}</p>
                                                    <DownloadIcon />
                                            </div>
                                        </a>
                                    )
                                })}
                                </Panel>
                            </Collapse>
                        </Fragment>}
                    </div>
                </div>
                <div style={actor==="customer" ? {position: 'absolute',bottom: '-5px',left: '6px'} : {position:'absolute',bottom:"-25px",right:'6px'}}>
                    <span style={{fontSize: '14px',fontWeight: '600',color: 'var(--color-primary)',cursor: 'pointer'}} onClick={()=>{handleReply(actor);}}><ReplyIcon /> Reply</span>
                    {to && cc && to!== null && cc!== null && to.length+cc.length > 1 && <span style={{marginLeft: '10px',fontSize: '14px',fontWeight: '600',color: 'var(--color-primary)',cursor: 'pointer'}}  onClick={()=>{handleReplyAll();}}><ReplyallIcon /> Reply All</span>}
                    <span style={{fontSize: '14px',fontWeight: '600',color: 'var(--color-primary)',cursor: 'pointer', marginLeft: '10px'}} onClick={()=>{handleForward(id);}}><LuForward /> Forward</span>
                </div>
            </div>
        )
    } else {
        return null
    }
}

const MessageContainer = (props) => {
    const { messages, openPanel, conversation_source, inputElement, setCommentReply, setCommentName, setInput, setCommentID, setPostID, setHideReply, setCommentIndex, newChat, setNewChat, setPhone, fromID, setFromID, compose, setCompose, merchantID, setEmailMsgID, setCcID, setCC, subject, setToID, ticket: { status, session_expiry_at }, setTicket, setValue, setDisableSubject } = props;
    const loading = useSelector(loadingSelector);
    const ConvPanel = useRef(null);
    const [showScroll, setShowScroll] = useState(false);
    const [scrollTop,setScrollTop] = useState(0);
    const allTickets = useSelector(derivedAllTickets);
    
    const checkScrollTop = () => {
        setScrollTop(ConvPanel.current.scrollTop);
        if (ConvPanel.current.scrollTop < -250){
            setShowScroll(true);
        } else {
            setShowScroll(false);
        }
    };

    const scrollToBottom = ()=>{
        ConvPanel.current.scrollTo({ 
            top: document.documentElement.scrollHeight, 
            behavior: 'smooth'
          });
    }

    const { completed } = calcTimeDelta(new Date(session_expiry_at).getTime());

    if (loading) {
        return (<StyledMessageContainer className="loading">
            <Skeleton avatar paragraph={{ rows: 3 }} />
            <Skeleton className="flip-loader" active paragraph={{ rows: 5 }} avatar />
            <Skeleton avatar paragraph={{ rows: 2 }} />
            <Skeleton className="flip-loader" avatar active paragraph={{ rows: 1 }} />
            <Skeleton paragraph={{ rows: 3 }} avatar />
        </StyledMessageContainer>
        )
    }

    const scrollToTicket = (ticket)=>{
        const content = ticket.content.filter(a=>a.content_type=="message");
        const idArray = content.map(a=>a.id);
        const min = Math.min(...idArray);
        const index = idArray.indexOf(min);
        if(!(ticket.requested_ticket)){
            const element = document.getElementById(content[index].message_id);
            element.scrollIntoView({behavior: 'smooth',block: 'nearest', inline: 'start'});
        }else{
            ConvPanel.current.scrollTo({ 
                top: document.documentElement.scrollHeight, 
                behavior: 'smooth'
            });
        }
    }

    return (
        <StyledMessageContainer onScroll={()=>checkScrollTop()} className='message-box' ref={ConvPanel}>
            {allTickets && allTickets.length > 1 && <div style={{position: 'fixed', top:'180px',display: 'flex',flexDirection: 'column',marginLeft: '-16px',justifyContent: 'space-between',alignItems: 'stretch',flex: '1',height: '60%',zIndex: '10'}}>
                {allTickets.map((ticket,i)=>{
                    return (
                        <StyledTicketHistory onClick={()=>scrollToTicket(ticket)} key={i}>
                            <span className="ticket-num" style={{color: '#333',fontSize: '12px',padding: '5px 10px',marginTop: '1px'}}>#{ticket.id}</span>
                        </StyledTicketHistory>
                    )
                })}
                <div style={{borderLeft: '1px dashed #00000066',position:'absolute',top: '0',height: '100%',left: '7px',zIndex: '0'}}></div> 
            </div>}
            {messages.map((message, i) => {
                return <Message messages={messages} key={i} ConvPanel={ConvPanel} setHideReply={setHideReply} setPostID={setPostID} message={message} setCommentID={setCommentID} setInput={setInput} setCommentName={setCommentName} setCommentReply={setCommentReply} inputElement={inputElement} conversation_source={conversation_source} content_type={message.content_type} account_id={merchantID} fromEmail={message.from && message.from.email} toEmail={message.to && message.to} ccEmail={message.cc && message.cc} msgID={message.convmsg_message_id} bccEmail={message.bcc && message.bcc} setCommentIndex={setCommentIndex} fromID={fromID} setFromID={setFromID} setCompose={setCompose} compose={compose} setEmailMsgID={setEmailMsgID} setCcID={setCcID} setCC={setCC} subject={subject} setToID={setToID} completed={completed} status={status} scrollTop={scrollTop} setTicket={setTicket} setValue={setValue} setDisableSubject={setDisableSubject} />
            })}
            {showScroll &&
                <StyledGoTo onClick={()=>scrollToBottom()} openPanel={openPanel}>
                    <DownArrowIcon style={{stroke: '#fff',width: '15px',height:'15px'}} />
                    <DownArrowIcon style={{stroke: '#fff',width: '15px',height:'15px',marginTop: '-10px'}} />
                </StyledGoTo>
            }
        </StyledMessageContainer>
    )
}

export default React.memo(MessageContainer);